.App {
  font-family: var(--Poppins) !important;
  font-weight: 400;

  color: var(--text-color0);
  background-color: var(--background-color0);
}

.MuiTypography-root {
  font-family: var(--Poppins) !important;
}
/* 
.MuiFormControl-root {
  filter: contrast(0) brightness(0);
} */

.MuiInputBase-input {
  filter: brightness(1) !important;
}

.MuiAccordion-root {
  border: 1px solid #e2e2e2;
  box-shadow: none !important;
}

.remove-underline:hover {
  text-decoration: none !important;
}

.wrapper-class {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.editor-class {
  background-color: rgb(248, 248, 248);
  border: 1px solid #ececec;
}

.toolbar-class {
  border: 1px solid #ececec;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: rgb(var(--accent-color1));
}

a:hover {
  text-decoration: underline;
}

img {
  display: block;
  object-fit: contain;
}

button,
label {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-family: var(--Poppins) !important;
}

button:hover,
input[type="submit"]:hover,
label:hover {
  filter: brightness(1.4);
}

button:active,
input[type="submit"]:active,
label:active {
  position: relative;
  transform: translateY(0.2rem);
  transition: none !important;
}

textarea {
  resize: vertical;
  margin-top: 0.8rem;
  border: none;
  padding: 0.5rem 0;
  border-bottom: 1.2px solid #8f8f8f;
  font-family: var(--Poppins) !important;
  font-size: 0.9rem;
}

textarea:focus {
  border-bottom: 2px solid #000;
}

th {
  padding-bottom: 1rem;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

:root {
  --Poppins: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --Fira: "Fira Mono", monospace;
}

/* input */

select {
  padding: 1rem 1rem;
  font-size: 1rem;
  border: 1px solid #e6e6e6;
  background-color: #f9f9f9;
}

/* start scrollbar */
::-webkit-scrollbar {
  width: 1rem;
  height: 1.125rem;
  background-color: white !important;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  height: 0.375rem;
  border: 0.25rem solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 0.438rem;
  border-radius: 0.5rem;
  background-color: #6d6d6d !important;
  -webkit-box-shadow: inset -1px -1px 0px rgb(0 0 0 / 5%),
    inset 1px 1px 0px rgb(0 0 0 / 5%);
  box-shadow: inset -1px -1px 0px rgb(0 0 0 / 5%),
    inset 1px 1px 0px rgb(0 0 0 / 5%);
}

/* fonts */
/* avenir */
@font-face {
  font-family: "Avenir";
  font-weight: 700;
  src: local("avenir"),
    url("./fonts/avenir/Metropolis-Black.otf") format("opentype");
}

@font-face {
  font-family: "Avenir";
  font-weight: 400;
  src: local("avenir"),
    url("./fonts/avenir/Metropolis-Regular.otf") format("opentype");
}

/* poppins */
@font-face {
  font-family: "Poppins";
  font-weight: 900;
  src: local("poppins"),
    url("./fonts/poppins/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 400;
  src: local("poppins"),
    url("./fonts/poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 200;
  src: local("poppins"),
    url("./fonts/poppins/Poppins-ExtraLight.ttf") format("truetype");
}


/* input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    background-color: transparent !important;
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s !important;
    box-shadow: inset 0 0 20px 20px lightgray !important;
} */

