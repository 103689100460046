.customTable {
  margin-top: 1rem;
}

.customTable thead {
  background-color: #f2f2f2;
}

.customTable th {
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--color-text-title);
  text-align: left;
  padding: 1rem 1.4rem;
}

.customTable th:last-child,
.customTable td:last-child {
  text-align: right;
}

.customTable td {
  padding: 1rem 1.4rem;
  border-bottom: 1px solid var(--color-line-in-white);
  font-size: 1rem;
  font-weight: 400;
  color: var(--color-text-base);
}

.customTable td:first-child {
  padding: 0;
}

.tdImage {
  width: 5rem;
}

.tdImage img {
  height: 4rem;
  width: 100%;
  object-fit: cover;
}
